<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import FormAuthor from "@/components/forms/form-author/index";

import {
    authorMethods,
    notificationMethods,
} from "@/state/helpers";

/**
 * Author Form component
 */
export default {
  page: {
    title: "Cadastrar Autor",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FormAuthor },
  data() {
    return {
      title: "Cadastrar Autor",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Autores",
          href: "/authors",
        },
        {
          text: "Cadastrar Autor",
          active: true,
        },
      ],
      editingAuthor: {
        name: "",
        surname: "",
        email: "",
        description: "",
        image: "",
      },
      loading: false,
    };
  },
  props: ['id'],
  created() {
    if(this.id) {
      this.getById();
    }
  },
  methods: {
    ...authorMethods,
    ...notificationMethods,    
    async getById() {
      this.loading = true; 
      const author = await this.getAuthor(this.id);
      setTimeout(() => {
        this.editingAuthor = author.data;
      }, 200)
      this.loading = false;
    },

    handleNewCad() {      
      this.$router.go(-1);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row justify-content-center">      
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Formulário de Cadastro de Autor</h4>
            <b-spinner
              v-if="loading"
              label="Spinning"
              variant="info"
              class="m-1"
            ></b-spinner>
            <FormAuthor              
              v-if="!loading"
              :parent="this"
              :editingAuthor="editingAuthor"
              @newCad="handleNewCad"
              @newCadSuccess="handleNewCad"
            ></FormAuthor>            
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
